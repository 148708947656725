<template>
  <div>
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('md-models.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="!isVisibleDialog"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="mdModel.name"
                    dense
                    :label="$t('name_*')"
                    :rules="[rules.required, rules.max_length(50)]"
                  />
                </v-col>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="4"
                >
                  <v-autocomplete
                    v-model="mdModel.product"
                    dense
                    :items="productOptions"
                    item-text="name"
                    item-value="slug"
                    :label="$t('select_product_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  v-if="mdModel.product"
                  cols="12"
                  sm="4"
                >
                  <v-autocomplete
                    v-model="mdModel.tenant_id"
                    dense
                    :disabled="tenantLoading"
                    :loading="tenantLoading"
                    :items="tenantsListing"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_tenant')"
                    :rules="[rules.required]"
                    @change="getTenantTech()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <!-- item-value="id" -->
                  <!-- :disabled="!isMdMakeShow" -->
                  <v-select
                    v-model="md_make"
                    dense
                    :items="isMdMakeShow ? mdMakesList : []"
                    :loading="isLoadingMdMakes"
                    item-text="name"
                    :label="$t('ev_make_*')"
                    return-object
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="mdModel.battery_make_id"
                    dense
                    :items="mdModel.md_make_id ? batteryMakesList : []"
                    :loading="isLoadingBatteryMakesList"
                    item-value="id"
                    item-text="name"
                    :label="$t('battery_make_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="mdModel.battery_model_id"
                    dense
                    :items="mdModel.battery_make_id ? batteryModelsList : []"
                    :loading="isLoadingBatteryModels"
                    item-value="id"
                    item-text="name"
                    :label="$t('battery_model_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="mdModel.is_active"
                    dense
                    :items="statusLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('status_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="mdModel.fuel_type"
                    dense
                    :items="fuelTypesList"
                    item-text="name"
                    item-value="id"
                    :label="$t('fuel_type') + ' *'"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="mdModel.motor_type_id"
                    dense
                    :items="motorTypesList"
                    item-text="name"
                    item-value="id"
                    :label="$t('motor_type_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="mdModel.battery_slot_type"
                    dense
                    :items="batterySlotTypesList"
                    item-text="name"
                    item-value="id"
                    :label="$t('battery_slot_type_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="mdModel.body_type"
                    dense
                    :items="bodyTypeList"
                    item-text="name"
                    item-value="id"
                    :label="$t('body_type_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="mdModel.start_year"
                    dense
                    type="number"
                    :min="getStartingYear()"
                    :max="getCurrentYear()"
                    :label="$t('start_year_*')"
                    :rules="[
                      rules.required,
                      rules.min_year(),
                      rules.max_year(),
                    ]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="mdModel.end_year"
                    dense
                    type="number"
                    :min="getStartingYear()"
                    :max="getCurrentYear()"
                    :label="$t('end_year')"
                    :rules="[endYearRule(mdModel.start_year, mdModel.end_year)]"
                  />
                </v-col>

                <v-col
                  v-if="!isVehicleFixedSlotType"
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="mdModel.no_of_batteries"
                    dense
                    type="number"
                    :label="$t('no_of_batteries_*')"
                    :rules="[
                      rules.required,
                      rules.min_quantity(1),
                      rules.max_quantity(10),
                      rules.max_length(1),
                    ]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="mdModel.motor_power"
                    dense
                    type="number"
                    :label="$t('motor_power') + ' *'"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="mdModel.battery_capacity"
                    dense
                    type="number"
                    :label="$t('battery_capacity') + ' *'"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="mdModel.fuel_economy"
                    dense
                    type="number"
                    :label="$t('per_km_cost') + ' *'"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="mdModel.comp_fuel_avg_kmpl"
                    dense
                    type="number"
                    :label="$t('fuel_economy') + ' *'"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="mdModel.mileage"
                    dense
                    type="number"
                    :label="$t('mileage_*')"
                    :rules="[
                      rules.required,
                      rules.max_quantity(100),
                      rules.min_quantity(0),
                    ]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-file-input
                    v-model="mdModel.image"
                    dense
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    :label="$t('image_*')"
                    :rules="[rules.required, rules.file_size]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(mdModel)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      productOptions: [
        { slug: "oem", name: "OEM" },
        { slug: "fleet", name: "Fleet" },
      ],
      mdModel: {
        make_type: "tenant",
      },
      md_make: {},
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      fuelTypesList: [
        {
          id: "petrol",
          name: "Petrol",
        },
        {
          id: "hsd",
          name: "HSD",
        },
      ],
      bodyTypeList: [
        {
          id: "bike",
          name: "Bike",
        },
        {
          id: "loader",
          name: "Loader",
        },
        {
          id: "sedan",
          name: "Sedan",
        },
        {
          id: "hatchback",
          name: "Hatchback",
        },
        {
          id: "suv",
          name: "SUV",
        },
        {
          id: "truck",
          name: "Truck",
        },
        {
          id: "bus",
          name: "Bus",
        },
      ],
      batterySlotTypesList: [
        {
          id: "fixed",
          name: "Fixed",
        },
        {
          id: "swappable",
          name: "Swappable",
        },
      ],
      vechicleTenant: 'ice'
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      vehicleTypesList: "vehicleTypes/vehicleTypesList",
      motorTypesList: "motorTypes/motorTypesList",
      mdMakesList: "mdMakes/activelistMdMakes",
      isLoadingMdMakes: "mdMakes/isLoadingMdMakes",
      batteryMakesList: "batteryMakes/batteryMakesList",
      isLoadingBatteryMakesList: "batteryMakes/isLoadingBatteryMakesList",
      batteryModelsList: "batteryModels/batteryModelsList",
      isLoadingBatteryModels: "batteryModels/isLoadingBatteryModels",
    }),
    tenantsListing() {
      if (this.mdModel.product) {
        if (this.mdModel.product == "oem") {
          return this.tenantsList.filter((r) => r.product == "oem");
        } else if (this.mdModel.product == "fleet") {
          return this.tenantsList.filter(
            (r) => r.product == "fleet" && r.is_independent_fleet
          );
        } else return [];
      } else return [];
    },
    isMdMakeShow() {
      if (this.mdModel.product == "fleet" || !this.authUser.isAdmin) {
        return true;
      } else if (this.mdModel.product == "oem" && this.mdModel.tenant_id) {
        return true;
      } else {
        return false;
      }
    },

    isVehicleFixedSlotType() {
      return this.mdModel.battery_slot_type == "fixed";
    },
  },

  watch: {
    "mdModel.product": {
      handler: async function (value) {
        delete this.mdModel.tenant_id;
        this.md_make = null;
        delete this.mdModel.md_make_id;
        delete this.mdModel.battery_make_id;
        delete this.mdModel.battery_model_id;

        if (value == "fleet") {
          this.$store.dispatch("mdMakes/list");
        }
      },
    },
    "mdModel.tenant_id": {
      handler: async function (value) {
        if (value) {
          this.md_make = null;
          delete this.mdModel.md_make_id;
          delete this.mdModel.battery_make_id;
          delete this.mdModel.battery_model_id;
          await this.$store.dispatch("mdMakes/list", { tenant_id: value });
        }
      },
    },
    md_make: {
      handler: async function (value) {
        if (value) {
          delete this.mdModel.battery_make_id;
          delete this.mdModel.battery_model_id;
          this.mdModel.md_make_id = value.id;
          const id = value.tenant_id
            ? value.tenant_id
            : this.mdModel.product == "oem"
              ? this.mdModel.tenant_id
              : this.mdModel.product;
          this.$store.dispatch(
            "batteryMakes/list",
            id == "fleet" ? { product: id } : { tenant_id: id }
          );
        }
      },
    },
    "mdModel.battery_make_id": {
      handler: async function (value) {
        if (value) {
          await this.$store.dispatch("batteryModels/list", {
            battery_make_id: value,
          });
        }
      },
    },
    "mdModel.battery_slot_type": {
      handler: async function () {
        if (this.isVehicleFixedSlotType) {
          this.mdModel.no_of_batteries = 1;
        }
      },
    },
  },

  methods: {
    getTenantTech() {
      const tenantTech = this.tenantsList.find(tenant => tenant.id == this.mdModel.tenant_id)
      this.vechicleTenant = tenantTech.vehicle_tech;
    },
    closeForm() {
      this.$refs.form.reset();
      this.isVisibleDialog = false;
    },
    async saveItem(mdModel) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        let params = { mdModel, filter: { ...this.filterOptions } };
        await this.$store
          .dispatch("mdModels/add", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>