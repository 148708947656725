import service from "@/store/services/csms-bookings-service";
import serviceAdx from "@/helpers/service";

const state = {
  loading: false,
  meta: {},
  list: [],
  customerList: [],
  bookingDetail: []
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },

  SET_CUSTOMER(state, payload) {
    state.customerList = payload;
  },

  SET_BOOKING_DETAILS(state, payload) {
    state.bookingDetail = payload;
  },

  // UPDATE_LIST(state, payload) {
  //   const item = state.list.find((item) => item.id === payload.id);
  //   Object.assign(item, payload);
  // },

  // DELETE_LIST(state, payload) {
  //   state.list.splice(state.list.map((o) => o.id).indexOf(payload.id), 1);
  // },
};

const actions = {
  list: (store, params) => {
    store.state.loading = true;
    return service.list(params).then((data) => {
      if (data?.body?.meta) store.commit("SET_META", data.body);
      store.commit("SET_LIST", data.body);
      store.state.loading = false;
    });
  },

  getCustomers: (store, params) => {
    serviceAdx
      .get(`bookings/get-csms-customer`, params)
      .then((res) => {
        store.commit("SET_CUSTOMER", res.body);
      });
  },
  getBooking: (store, params) => {
    store.state.loading = true;
    return serviceAdx
      .get(`bookings/details/${params.id}`)
      .then((data) => {
        store.commit("SET_BOOKING_DETAILS", data.body);
        return data.body;
      })
      .finally(() => (store.state.loading = false));

  },
  addBooking: (store, params) => {
    store.state.loading = true;
    return serviceAdx
      .post("bookings/book-charging-connectors-from-portal", params)
      .then((data) => {
        return data;
      })
      .finally(() => (store.state.loading = false));
  },
  updateBooking: (store, params) => {
    store.state.loading = true;
    return serviceAdx
      .put(`bookings/update-charging-connectors-from-portal/${params.id}`, params)
      .then((data) => {
        return data;
      })
      .finally(() => (store.state.loading = false));
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
  getCustomerList(state) {
    return state.customerList;
  },
  getBookingDetail(state) {
    return state.bookingDetail;
  }
};

const csmsBookings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default csmsBookings;
