<template>
  <div>
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="960px"
    >
      <booking-form
        v-if="isVisibleDialog"
        type="Update"
        :item-data="bookingItem"
        @change="change"
        @onclose="closeForm"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  components: {
    BookingForm: () => import("./util/BookingForm.vue"),
  },
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },

  },
  data() {
    return {
      isLoadingSave: false,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      bookingItem: "csmsBookings/getBookingDetail",
    }),
  },
  methods: {
    closeForm() {
      this.toggleEdit();
    },
    change() {
      this.toggleEdit();
      this.$emit('change');
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

.searchBoxwithBtn
  display: flex
  flex-wrap: wrap
  align-items: center
  :deep .v-input
    width: 70%
    margin-right: 1.75rem
  :deep button
    margin: 0 !important
.toptitle
  #backBtn
    padding: 0 !important
    position: absolute
    left: 27px
    background: #eee
    display: flex
    align-items: center
    justify-content: center
    align-items: center
    margin: 0 !important
    :deep .v-btn__content
      .v-icon
        position: relative
        top: unset
        left: unset
        right: unset
        opacity: 1
        font-size: 21px
        color: #333
        height: 29px !important
.v-application
    .bodTop
        margin-top: 1rem !important
        display: block
        padding-top: 1rem
    .addNewCard
        box-shadow: 0 0 0 !important
        border: 0 !important
        .container
            padding-top: 0
            padding-bottom: 0
            .custom-h3
                font-size: 1.25rem !important
                color: #333
                font-family: $poppinMed !important
                margin-bottom: 0.75rem !important
                display: inline-block
.userAssignWrap
  display: flex
  margin: 0
  padding: 12px
  flex-wrap: wrap
  .rowWrap
    margin: 0 !important
    width: 100% !important
  .noMargin
    margin: -12px !important

.nopadding
  padding-left: 0 !important
  padding-right: 0 !important


</style>