<template>
  <div>
    <section class="d-flex align-center">
      <header-filters
        :is-date-show="true"
        class-name="dashboard"
        :filter-names="['calendar_type']"
        :product-options="productOptions"
        @onChange="onFiltersChange"
      >
        <v-card>
          <v-select
            v-model="customFilters.location_id"
            filled
            dense
            clearable
            :items="locationsList"
            item-value="id"
            item-text="name"
            :label="$t('location')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.charge_station_id"
            filled
            dense
            clearable
            :items="chargersList"
            item-value="charge_station_id"
            item-text="charge_point_id"
            :label="$t('charge_point_id')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.charger_connector_id"
            filled
            dense
            clearable
            :items="chargeConnectorList"
            item-value="id"
            item-text="charge_point_id_connector_id"
            :label="$t('connector_id')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.status"
            filled
            dense
            clearable
            :items="statusList"
            item-value="id"
            item-text="name"
            :label="$t('status')"
            @change="fetchData"
          />
        </v-card>
        <div class="d-flex align-center">
          <v-btn
            color="primary"
            class="ma-2"
            @click="toggleCreateBookingModal"
          >
            Add New
          </v-btn>
        </div>
      </header-filters>
    </section>
    <section>
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <div>
            <v-btn
              text
              icon
              small
              class="ma-2"
              @click="$refs.calendar.prev()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <div>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </div>
        </div>
        <div>
          <v-btn
            text
            icon
            small
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </section>
    <section>
      <v-sheet class="bookingCalendar">
        <v-calendar
          v-if="options.calendar_type"
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="options.calendar_type"
          :events="events"
          :event-overlap-mode="options.mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @click:date="viewDay"
          @click:event="showEvent"
          @change="fetchData"
        />
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            class="mb-0 vcardCustom"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <!-- For Edit -->
              <!-- <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->
              <v-toolbar-title>
                <div class="d-flex align-center justify-space-between">
                  <div class="name">
                    {{ selectedEvent?.name }}
                  </div>
                  <div class="number">
                    {{ selectedEvent?.phone_number }}
                  </div>
                </div>
                <div class="email">
                  {{ selectedEvent?.email }}
                </div>
              </v-toolbar-title>
              <!-- <v-spacer /> -->
              <!-- <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn> -->
            </v-toolbar>
            <v-card-text>
              <v-btn
                color="primary"
                class="ma-2"
                @click="openEditModal(selectedEvent)"
              >
                Edit
              </v-btn>
              <div>
                <label>{{ $t("location_address") }}</label>
                <span>{{ selectedEvent?.location_address }}</span>
              </div>
              <div>
                <label>{{ $t("status") }}</label>
                <span>{{ selectedEvent?.status }}</span>
              </div>
              <span class="d-flex justify-space-between">
                <div>
                  <label>{{ $t("vehicle_details") }}</label>
                  <span>{{ selectedEvent?.vehicle_details }}</span>
                </div>
                <div>
                  <label>{{ $t("connector_type") }}</label>
                  <span>{{ selectedEvent?.connector_type }}</span>
                </div>
                <div>
                  <label>{{ $t("connector_format") }}</label>
                  <span>{{ selectedEvent?.connector_format }}</span>
                </div>
              </span>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                text
                @click="selectedOpen = false"
              >
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </section>
    <!-- CreateBooking Modal -->
    <create-booking
      v-if="showCreateBookingModal"
      :is-visible-dialog="showCreateBookingModal"
      :toggle-edit="toggleCreateBookingModal"
      @change="fetchData"
    />

    <edit-booking
      v-if="showEditBookingModal"
      :is-visible-dialog="showEditBookingModal"
      :toggle-edit="toggleEditBookingModal"
      @change="fetchData"
    />
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    CreateBooking: () => import("./CreateBooking.vue"),
    EditBooking: () => import("./EditBooking.vue"),
  },
  data() {
    return {
      showCreateBookingModal: false,
      showEditBookingModal: false,
      showCreateGroupModal: false,
      options: {
        type: "week",
        mode: "stack",
      },
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "cso", value: "CSO" },
      ],
      types: ["week", "day"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      statusList: [
        {
          id: "pending_approval",
          name: "PENDING APPROVAL",
        },
        {
          id: "approved",
          name: "APPROVED",
        },
        {
          id: "rejected",
          name: "REJECTED",
        },
        {
          id: "RESCHEDULED",
          name: "rescheduled",
        },
        {
          id: "CANCELLED",
          name: "cancelled",
        },
        {
          id: "COMPLETED",
          name: "completed",
        },
        {
          id: "in_progress",
          name: "IN PROGRESS",
        },
      ],
      value: "",
      events: [],
      customFilters: {},
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsBookings/getLoading",
      list: "csmsBookings/getList",
      locationsList: "csmsChargeStations/getLocation",
      chargersList: "csmsChargeStations/getChargers",
      chargeConnectorList: "csmsChargeStations/getChargeConnectorList",
    }),
  },
  watch: {
    "customFilters.charge_station_id": {
      handler: function (val) {
        if (val) {
          const params = { charge_station_id: val };
          this.$store.dispatch("csmsChargeStations/chargeConnectorList", params);
        } else {
          this.$store.commit("csmsChargeStations/SET_CHARGE_CONNECTOR", []);
        }
        this.fetchData();
      },
    },
  },
  async mounted() {
    if (!this.authUser.isAdmin) {
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("csmsChargeStations/LocationsList", params);
    }
    if (this.$route?.query?.charger_connector_id) {
      this.customFilters.charger_connector_id = this.$route.query.charger_connector_id;
    }
    if (this.$route?.query?.location_id) {
      this.customFilters.location_id = parseInt(this.$route.query.location_id);
    }
    if (this.$route?.query?.status) {
      this.customFilters.status = (this.$route.query.status);
    }
    if (this.$route?.query?.charge_station_id) {
      this.customFilters.charge_station_id = parseInt(this.$route.query.charge_station_id);
      if (this.customFilters.charge_station_id) {
        const params = { charge_station_id: this.customFilters.charge_station_id };
        this.$store.dispatch("csmsChargeStations/chargeConnectorList", params);
      }
    }
  },
  beforeDestroy() {
    const paramsSet = { ...this.options, ...this.customFilters };
    const params = {
      name: "bookings",
      filters: paramsSet,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    toggleCreateBookingModal() {
      this.showCreateBookingModal = !this.showCreateBookingModal;
    },
    async openEditModal(item) {
      await this.$store.dispatch("csmsBookings/getBooking", item);
      this.showEditBookingModal = true;
    },
    toggleEditBookingModal() {
      this.showEditBookingModal = !this.showEditBookingModal;
    },
    toggleCreateGroupModal() {
      this.showCreateGroupModal = !this.showCreateGroupModal;
    },
    onFiltersChange(v) {
      this.options = v;
      if (v.tenant_id !== null) {
        const params = { tenant_id: v.tenant_id };
        this.$store.dispatch("csmsChargeStations/LocationsList", params);
        this.$store.dispatch("csmsChargeStations/chargerList", params);
      } else {
        // this.customFilters = {};
        this.$store.commit("csmsChargeStations/SET_LOCATION", []);
        this.$store.commit("csmsChargeStations/SET_CHARGERS", []);
      }
      this.fetchData();
    },
    fetchData: debounce(async function (val) {
      this.options.booking_date = this.options.date;
      this.options.booking_status = this.options.status;
      this.options.charger_connector_id = this.options.status;
      let paramsSet = { ...this.options, ...this.customFilters }
      await this.$store.dispatch("csmsBookings/list", paramsSet);
      await this.getEvents(val);
      ("");
    }, 100),

    getEvents() {
      const events = [];
      this.list.forEach((el) => {
        const params = {
          id: el?.booking_id, // Use the new field for customer name
          name: el?.customer_name, // Use the new field for customer name
          phone_number: el?.customer_phone_number, // Updated field
          email: el?.customer_email, // Updated field
          start: new Date(`${el.booking_date} ${el.start_time}`),
          end: new Date(`${el.booking_date} ${el.end_time}`),
          status: el?.booking_status,
          connector_type: el?.connector_type,
          connector_format: el?.connector_format,
          location_address: el?.location_name, // Use `location_name` for address
          vehicle_details: `${el?.vehicle_make_name} ${el?.vehicle_model_name} (${el?.vehicle_year})`, // Combine vehicle details
          color: el.booking_status === "approved" ? "green" : "grey darken-1",
          timed: true,

        };

        events.push(params);
      });

      this.events = events;
    },

    //
    viewDay({ date }) {
      this.value = date;
      this.options.calendar_type = "day";
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    //
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-present
    .v-calendar-daily_head-day-label
        .v-btn
            background-color  : #20a390 !important

:deep .v-past, :deep .v-future
    .v-calendar-daily_head-day-label
        .v-btn
            color : gray !important


:deep .v-toolbar
    width: 100% !important
    .v-toolbar__content, .v-toolbar__title
        width: inherit !important

    .v-toolbar__title
        .number
            font-size: 16px !important
        .email
            font-size: 14px !important
/*
:deep  .v-card__text
    div
        display: flex
        flex-direction: column
        margin-top: 0.5rem
        label
            font-size: 13px !important
            font-weight: 600 !important
        span
            font-size: 16px !important
            */

.bookingCalendar
  height: calc(100vh - 175px)
</style>