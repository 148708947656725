<template>
  <div class="map-container">
    <div class="mapTabsWrap">
      <v-tabs
        v-model="tab"
        class="customTab"
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <div v-if="item.content === 'allVehicles'">
            <div class="vehicles-listing">
              <div class="tabs-header top-filters">
                <v-card class="searchField">
                  <v-text-field
                    v-model="searchQuery"
                    label="Search"
                    filled
                    dense
                    clearable
                  />
                </v-card>
              </div>
              <div class="vehicles-items">
                <div
                  v-for="vehicle in filteredVehicles"
                  :key="vehicle.id"
                  class="vehicle-item"
                >
                  <v-checkbox
                    :input-value="vehicle.selected"
                    :label="vehicle.name"
                    @click="addToMap(vehicle)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.content === 'groups'">
            <div class="tabs-header top-filters">
              <v-card class="searchField">
                <v-text-field
                  v-model="searchQuery"
                  label="Search"
                  filled
                  dense
                  clearable
                />
              </v-card>
            </div>
            <div class="groups-listing">
              <div
                v-for="group in groups"
                :key="group.id"
                class="group-item"
              >
                <div
                  class="group-header"
                  @click="toggleGroup(group.id)"
                >
                  <v-checkbox
                    :input-value="group.selected"
                    :label="group.name"
                    @click.stop="toggleGroupSelection(group)"
                  />
                  <span class="groupTabAccordion">{{
                    isGroupOpen(group.id) ? "-" : "+"
                  }}</span>
                </div>
                <div
                  v-if="isGroupOpen(group.id)"
                  class="group-vehicles"
                >
                  <div
                    v-for="vehicle in group.vehicles"
                    :key="vehicle.id"
                    class="vehicle-item"
                  >
                    <v-checkbox
                      :input-value="vehicle.selected"
                      :label="vehicle.name"
                      @click="addToMap(vehicle)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <VehicleLiveTracking
      v-if="showMap"
      :channel-name="dashboadData.tenant_channel_name"
      :product="options.product"
      :vehicles="vehiclesForMap"
      class="map"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    VehicleLiveTracking: () =>
      import("@/components/sections/VueLeafLetMap/VehicleLiveTracking.vue"),
  },
  data() {
    return {
      options: {
        product: "defaultProduct",
      },
      showMap: true,
      activeTab: "allVehicles",
      searchQuery: "",
      tab: 0,
      items: [
        { tab: "All Vehicles", content: "allVehicles" },
        { tab: "Groups", content: "groups" },
      ],
      hardcodedVehicles: [
        {
          id: 1,
          name: "BH-120 F 2/1 Fleet (5)",
          lt: 24.8607,
          ln: 67.0011,
          selected: true,
        },
        {
          id: 2,
          name: "BH-121 G 2/2 Fleet (4)",
          lt: 24.8707,
          ln: 67.0211,
          selected: true,
        },
        {
          id: 3,
          name: "BH-122 H 2/3 Fleet (6)",
          lt: 24.8807,
          ln: 67.0411,
          selected: true,
        },
        {
          id: 4,
          name: "BH-120 F 2/1 Fleet (5)",
          lt: 24.8607,
          ln: 67.0011,
          selected: true,
        },
        {
          id: 5,
          name: "BH-121 G 2/2 Fleet (4)",
          lt: 24.8707,
          ln: 67.0211,
          selected: true,
        },
        {
          id: 6,
          name: "BH-122 H 2/3 Fleet (6)",
          lt: 24.8807,
          ln: 67.0411,
          selected: true,
        },
        {
          id: 7,
          name: "BH-122 H 2/3 Fleet (6)",
          lt: 24.8807,
          ln: 67.0411,
          selected: true,
        },
        {
          id: 8,
          name: "BH-120 F 2/1 Fleet (5)",
          lt: 24.8607,
          ln: 67.0011,
          selected: true,
        },
        {
          id: 9,
          name: "BH-121 G 2/2 Fleet (4)",
          lt: 24.8707,
          ln: 67.0211,
          selected: true,
        },
        {
          id: 10,
          name: "BH-122 H 2/3 Fleet (6)",
          lt: 24.8807,
          ln: 67.0411,
          selected: true,
        },
      ],
      groups: [
        {
          id: 1,
          name: "Group A",
          selected: true,
          vehicles: [
            {
              id: 4,
              name: "BH-123 A 2/4 Fleet (3)",
              lt: 24.8907,
              ln: 67.0511,
              selected: true,
            },
            {
              id: 5,
              name: "BH-124 B 2/5 Fleet (2)",
              lt: 24.9007,
              ln: 67.0611,
              selected: true,
            },
          ],
        },
        {
          id: 2,
          name: "Group B",
          selected: true,
          vehicles: [
            {
              id: 1,
              name: "BH-120 F 2/1 Fleet (5)",
              lt: 24.8607,
              ln: 67.0011,
              selected: true,
            },
            {
              id: 2,
              name: "BH-121 G 2/2 Fleet (4)",
              lt: 24.8707,
              ln: 67.0211,
              selected: true,
            },
            {
              id: 6,
              name: "BH-125 C 2/6 Fleet (7)",
              lt: 24.9107,
              ln: 67.0711,
              selected: true,
            },
          ],
        },
      ],
      openGroups: [],
    };
  },
  computed: {
    ...mapGetters({
      dashboadData: "dashboard/getDashboadSectionFour",
    }),
    filteredVehicles() {
      return this.hardcodedVehicles.filter((vehicle) =>
        vehicle.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    vehiclesForMap() {
      return [
        ...this.hardcodedVehicles.filter((vehicle) => vehicle.selected),
        ...this.groups
          .filter((group) => group.selected)
          .flatMap((group) =>
            group.vehicles.filter((vehicle) => vehicle.selected)
          ),
      ];
    },
  },
  methods: {
    addToMap(vehicle) {
      vehicle.selected = !vehicle.selected;
    },
    toggleGroup(groupId) {
      if (this.openGroups.includes(groupId)) {
        this.openGroups = [];
      } else {
        this.openGroups = [groupId];
      }
    },
    isGroupOpen(groupId) {
      return this.openGroups.includes(groupId);
    },
    toggleGroupSelection(group) {
      group.selected = !group.selected;
      group.vehicles.forEach((vehicle) => {
        vehicle.selected = group.selected;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
#map
  height: 94vh !important

.map-container
  position: relative

.mapTabsWrap
  position: absolute
  right: 15px
  top:30px
  max-width: 340px
  min-width: 340px
  z-index: 99
  background: rgba(255,255,255,0.85)

.tabs-header
  padding: 1rem
  display: flex
  flex-wrap: wrap

.searchField
  width: 100%

.vehicles-items
  display: flex
  flex-wrap: wrap
  margin: 0rem 1rem 1rem 1rem
  overflow: hidden
  overflow-y: auto
  max-height: 300px
  .vehicle-item
    display: flex
    flex-wrap: wrap
    background: #f3f3f3
    width: 100%
    padding: 0.5rem 0.75rem
    margin-right: 0.75rem
    margin-bottom: 0.25rem
    :deep .v-input
      margin-top: 0 !important
      .v-input__control
        .v-input__slot
          margin: 0 !important
          .v-label
            font-size: 0.9375rem
            font-family: $poppinReg

        .v-messages
          display: none !important

.groups-listing
  display: flex
  flex-wrap: wrap
  margin: 0rem 1rem 1rem 1rem
  overflow: hidden
  overflow-y: auto
  max-height: 300px
  .group-item
    display: flex
    flex-wrap: wrap
    width: 100%
    :deep .v-input
      margin-top: 0 !important
      .v-input__control
        .v-input__slot
          margin: 0 !important
          .v-label
            font-size: 0.9375rem
            font-family: $poppinReg

        .v-messages
          display: none !important

  .group-header
    display: flex
    flex-wrap: wrap
    width: 100%
    background: #f3f3f3
    padding: 0.5rem 0.75rem
    margin-right: 0.75rem
    margin-bottom: 0.25rem
    .groupTabAccordion
      margin-left: auto
      font-size: 1.25rem
      line-height: 1.75rem
      cursor: pointer
  .group-vehicles
    display: flex
    flex-wrap: wrap
    width: 100%
    padding: 0 1rem 1rem
    margin-top: 0.5rem
    .vehicle-item
      display: flex
      flex-wrap: wrap
      background: #f3f3f3
      width: 100%
      padding: 0.5rem 0.75rem
      margin-bottom: 0.25rem
</style>
